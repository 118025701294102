.btn-container {
  visibility: hidden;
}

.btn-container a {
  text-transform: uppercase;
  background-color: #a52432 !important;
  border-radius: 6px !important;
  font-family: Gill Sans, sans-serif !important;
  box-shadow: 0 1px 5px #a52432b3 !important;
}

@font-face {
  font-family: Gill Sans;
  src: url("https://worldmapcovid19.b-cdn.net/static/fonts/GillSans-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: Gill Sans;
  src: url("https://worldmapcovid19.b-cdn.net/static/fonts/GillSans-Light.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: Gill Sans;
  src: url("https://worldmapcovid19.b-cdn.net/static/fonts/GillSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@keyframes pulse {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

*, :after, :before {
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}

.red {
  color: #f6584c !important;
}

.light .red {
  color: #b13507 !important;
}

.blue {
  color: #54cbf2 !important;
}

.white {
  color: #faebd7 !important;
}

.black {
  color: #000 !important;
}

.gray {
  color: #cacaca !important;
}

.dark-gray {
  color: #757575 !important;
}

.yellow, .yellow-test {
  color: #ffc82a !important;
}

.light .yellow-test {
  color: #967000 !important;
}

.green {
  color: #4cf6af !important;
}

.light .green {
  color: #33a976 !important;
}

.dark-path {
  fill: #000 !important;
}

.light-path {
  fill: #fff !important;
}

.highlight-path {
  stroke-width: calc(.5px / var(--zoom)) !important;
}

.light .dark-path {
  fill: #fff !important;
}

.light .light-path {
  fill: #000 !important;
}

.stroke-gray {
  stroke: #282828;
}

.fill-gray {
  fill: #282828;
}

.light .stroke-gray {
  stroke: #e6e6e6;
}

.light .fill-gray {
  fill: #e6e6e6;
}

.text-center {
  text-align: center !important;
}

.ease-out {
  -o-transition: .3s ease-out;
  transition: all .3s ease-out;
}

.ease-in-out {
  -o-transition: .5s ease-in-out;
  transition: all .5s ease-in-out;
}

.transform {
  transform: translateX(-100%);
}

.transform-y-178 {
  transform: translateY(-178px);
}

.transform-y-260 {
  transform: translateY(-260px);
}

.transform-y-30 {
  transform: translateY(-30px);
}

.transform-y-50 {
  transform: translateY(-50px);
}

.transform-rotate {
  transform: rotate(180deg);
}

.flex-column {
  flex-direction: column;
  display: flex;
}

.no-display {
  display: none !important;
}

.no-visibility {
  visibility: hidden !important;
}

.bckg-sprite {
  background-image: url("https://worldmapcovid19.b-cdn.net/static/images/icons_sprites.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.bckg-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.full-opacity {
  opacity: 1 !important;
  -o-transition: none !important;
  margin: 0 !important;
  padding: 0 !important;
  transition: none !important;
}

.btns {
  background: none;
  border: none;
  outline: none;
  flex-shrink: 0;
  padding: 0;
  font-family: Gill Sans, sans-serif;
}

html, body {
  margin: 0;
  padding: 0;
  font-family: Gill Sans, sans-serif;
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkt-scroll-behavior: smooth;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  background-color: #282828;
  overflow: hidden;
}

.light {
  background-color: #e6e6e6;
}

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.flex-container {
  display: flex;
}

#header {
  opacity: 0;
  z-index: 12;
  -ms-touch-action: none;
  touch-action: none;
  background-color: #000;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 12px;
  position: fixed;
  box-shadow: 0 0 2px #000;
}

.light #header {
  background-color: #fff;
}

#logo {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.light #logo path {
  fill: #013544;
}

#header h1 {
  font-size: 1.5em;
  font-weight: 100;
}

.light #header h1 {
  color: #000 !important;
}

#nav {
  pointer-events: all;
}

.menu-btns {
  cursor: pointer;
  color: #faebd7;
  margin: 0;
}

.light .menu-btns {
  color: #000;
}

#worldBtn, #worldBtnMobile {
  color: #54cbf2;
}

#usBtn {
  margin-left: 20px;
}

#aboutBtn {
  margin: 0 20px;
  text-decoration: none;
}

#hamburger, #mobileNav {
  display: none;
}

@media (min-width: 769px) {
  #searchWrapper {
    background-color: #282828 !important;
    flex: 1 !important;
    margin-right: 20px !important;
  }

  .light #searchWrapper {
    background-color: #e6e6e6 !important;
  }

  #header h1, #closeSearch {
    display: block !important;
  }

  #searchInput {
    visibility: visible !important;
  }
}

.flex-1 {
  flex: 1 !important;
}

#searchWrapper {
  pointer-events: all;
  background-color: #282828;
  border-radius: 5px;
  align-items: center;
  margin: 0 20px;
  display: flex;
}

#searchIcon {
  fill: #faebd7;
  width: 25px;
  height: 25px;
  margin: 0 5px;
}

.light #searchIcon {
  fill: #000;
}

#searchInput {
  background-color: inherit;
  color: #faebd7;
  border: none;
  outline: none;
  width: calc(100% - 68px);
  height: 30px;
}

.light #searchInput {
  color: #282828;
}

#closeSearch {
  cursor: pointer;
  visibility: hidden;
  fill: #faebd7;
  border: none;
  outline: none;
  width: 25px;
  height: 25px;
  margin: 0 4px;
}

.light #closeSearch {
  fill: #282828;
}

#resultsWrapper {
  z-index: 10;
  visibility: hidden;
  -ms-touch-action: none;
  touch-action: none;
  background-color: #1b1b1b;
  border-radius: 0 0 8px 8px;
  width: auto;
  max-height: 250px;
  padding: 10px 0;
  position: fixed;
  top: 50px;
  overflow-y: auto;
}

.light #resultsWrapper {
  background-color: #e6e6e6;
  box-shadow: 0 0 2px #000;
}

.results-flex {
  color: #faebd7;
  cursor: pointer;
  align-items: center;
  width: 100%;
  padding: 5px 10px;
  display: flex;
}

.light .results-flex {
  color: #000;
}

.results-flex-focus {
  background-color: #79d7f4;
  color: #000 !important;
}

.results-flex:hover {
  background-color: #79d7f4;
}

.results-flex:hover .inline-p {
  color: #000 !important;
}

#globalInstructions {
  color: #faebd7;
  text-align: center;
  opacity: .5;
  z-index: 2;
  -ms-touch-action: none;
  touch-action: none;
  background-color: #000;
  width: 100%;
  font-size: .8em;
  position: absolute;
  top: 50px;
}

#globalInstructions p {
  margin: 5px 2px;
}

#closePopup {
  cursor: pointer;
  -o-transition: .3s ease-in-out;
  z-index: 10;
  visibility: hidden;
  pointer-events: all;
  border: none;
  outline: none;
  width: 20px;
  height: 20px;
  margin-left: -30px;
  margin-right: 10px;
  transition: all .3s ease-in-out;
}

.closepopup-change {
  visibility: visible !important;
  margin-left: -5px !important;
}

#closePopup polyline {
  stroke: #fff;
}

.light #closePopup polyline {
  stroke: #000;
}

#countryPopup {
  z-index: 8;
  opacity: .9;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  background-color: #282828;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  margin: 50px auto;
  padding: 0 10px;
  display: flex;
  position: absolute;
  overflow: auto;
}

.light #countryPopup {
  background-color: #cacaca;
}

.country-wrapper {
  min-height: calc(calc(100 * var(--vh))  - 60px);
  background-color: #1b1b1b;
  border-radius: 8px;
  margin: 5px 0;
  padding: 0 10px 100px;
  position: sticky;
  top: 5px;
}

.light .country-wrapper {
  background-color: #e6e6e6;
}

.country-container {
  color: #faebd7;
  margin: 0 5px 20px;
  display: block;
}

.light .country-container {
  color: #000;
}

.us-btn {
  text-transform: uppercase;
  color: #e9e9e9;
  cursor: pointer;
  background-color: #a52432;
  border: none;
  border-radius: 8px;
  outline: none;
  padding: 5px 10px;
  font-family: Gill Sans, sans-serif;
  font-size: 1.5em;
  font-weight: 100;
  text-decoration: none;
  position: relative;
  top: 10px;
  box-shadow: 0 1px 5px #9a2532;
}

.flag-big {
  pointer-events: none;
  width: 120px;
  height: 120px;
}

.country-big {
  pointer-events: none;
  margin: 0;
  font-size: 2em;
}

.stats-column {
  pointer-events: none;
  background-color: #000;
  border: 1px solid #fff;
  border-radius: 8px;
  min-width: 220px;
  margin: 10px 0;
  padding: 20px;
}

.prop-title, .prop-title-main {
  text-transform: uppercase;
  color: #54cbf2;
  pointer-events: none;
  width: calc(100% - 40px);
  margin: 0;
  font-size: 1.5em;
  font-weight: 100;
}

.light .prop-title, .light .prop-title-main .light .chart-header {
  color: #038ebc;
}

.flex-stats-container {
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2.5px;
  margin-left: .5%;
  padding-bottom: 75px;
  display: flex;
}

.stats-column-flex {
  background-color: #000;
  border-radius: 8px;
  flex-wrap: wrap;
  place-content: flex-start center;
  align-items: center;
  width: calc(33% - 5px);
  margin: 2.5px;
  padding: 20px;
  display: flex;
  position: relative;
}

.light .stats-column-flex, .light #chart {
  background-color: #fff;
}

.stats-column-flex-100 {
  width: calc(99% - 5px);
}

.help-tip-stats {
  position: absolute !important;
  top: 10px !important;
  right: 10px !important;
}

.help-tip-stats p {
  right: -4px !important;
  left: unset !important;
  width: 250px !important;
}

.help-tip-stats p:before {
  right: 10px !important;
  left: unset !important;
}

.help-tip-stats p:after {
  height: 0;
}

.help-tip-chart {
  pointer-events: all;
  top: -10px !important;
  right: -10px !important;
}

.flex-stat {
  color: #faebd7;
  pointer-events: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
  display: flex;
}

.light .flex-stat {
  color: #000;
}

.flex-stat-global {
  width: 60px !important;
  height: 70px !important;
  margin: 0 !important;
}

.pie-wrapper {
  pointer-events: none;
  width: 100px;
  height: 100px;
}

.pie-wrapper-global {
  width: 60px !important;
  height: 70px !important;
  margin-top: 0 !important;
}

.circle {
  width: 100%;
  height: 86px;
  transform: rotate(135deg);
}

.circle-info {
  color: #6dff71;
  text-align: center;
  position: relative;
  top: -2.3em;
  left: 0;
  right: 0;
}

.circle-perc {
  top: -3.5em;
}

#chart {
  min-height: unset;
  opacity: 1;
  pointer-events: none;
  background-color: #000;
  border-radius: 8px;
  width: calc(99% - 5px);
  height: 100px;
  margin: 2.5px 0;
  padding: 20px;
  position: relative;
}

.chart-change {
  height: calc(calc(75 * var(--vh))  - 60px) !important;
  min-height: 350px !important;
}

.chart-change-nodata {
  height: 115px !important;
  min-height: 115px !important;
}

#chartLoader {
  z-index: 99;
  width: 80px;
  height: 80px;
  display: inline-block;
  position: absolute;
  top: 15px;
  left: calc(50% - 40px);
}

.chart-loader div {
  opacity: 1;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: 1s cubic-bezier(0, .2, .8, 1) infinite lds-ripple;
  position: absolute;
}

.chart-loader div:nth-child(2) {
  animation-delay: -.5s;
}

.light .chart-loader div {
  border-color: #171717;
}

@keyframes lds-ripple {
  0% {
    opacity: 1;
    width: 0;
    height: 0;
    top: 36px;
    left: 36px;
  }

  100% {
    opacity: 0;
    width: 72px;
    height: 72px;
    top: 0;
    left: 0;
  }
}

.chart-no-data {
  text-align: center;
  margin-top: 15px;
  font-weight: 100;
}

.chart-header {
  color: #54cbf2;
  flex-wrap: wrap;
  align-items: center;
  width: calc(100% - 40px);
  padding-bottom: 10px;
  display: flex;
  position: absolute;
}

.chart-header h2 {
  text-align: left;
  width: auto;
  max-width: calc(100% - 36px);
  margin-right: 10px;
  font-weight: 100;
}

.help-tip {
  top: -1px;
  left: unset;
  text-align: center;
  cursor: default;
  background-color: #faebd7;
  border: none;
  border-radius: 50%;
  outline: none;
  width: 24px;
  height: 24px;
  font-family: Gill Sans, sans-serif;
  font-size: 14px;
  line-height: 24px;
  display: inline-block;
  position: relative;
  padding: 0 !important;
}

.help-tip:before {
  content: "i";
  color: #000;
  font-family: serif;
  font-size: 1.3em;
  font-style: italic;
  font-weight: bold;
}

.light .help-tip:before {
  color: #faebd7;
}

.help-tip:hover p {
  transform-origin: 100% 0;
  animation: .3s ease-in-out fadeIn;
  display: block;
}

.help-tip p {
  text-align: left;
  color: #000;
  z-index: 11;
  background-color: #faebd7;
  border-radius: 3px;
  width: 300px;
  margin: 8px 0;
  padding: 20px;
  font-size: 13px;
  line-height: 1.4;
  display: none;
  position: absolute;
  left: -4px;
  box-shadow: 1px 1px 1px #0003;
}

.light .help-tip p, .light .help-tip {
  color: #faebd7;
  background-color: #282828;
}

.light .help-tip p:before {
  border-bottom-color: #282828;
}

.help-tip p:before {
  content: "";
  border: 6px solid #0000;
  border-bottom-color: #faebd7;
  width: 0;
  height: 0;
  position: absolute;
  top: -12px;
  left: 10px;
}

.help-tip p:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.chart-options-wrapper {
  z-index: 10;
  -o-transition: height .3s ease-out;
  pointer-events: all;
  border-radius: 8px;
  width: 175px;
  height: 30px;
  margin: 0;
  padding: 0;
  transition: height .3s ease-out;
  position: relative;
  overflow: hidden;
}

.light .chart-options-wrapper {
  border: 1px solid #000;
}

.chart-dropdown {
  color: #fff;
  cursor: pointer;
  background-color: #282828;
  border-bottom: 1px solid #1d1d1d;
  justify-content: space-between;
  height: 30px;
  display: flex;
}

.light .chart-dropdown {
  color: #000;
  background-color: #e6e6e6;
}

.chart-btns {
  color: #faebd7 !important;
  height: 30px !important;
}

.light .chart-btns {
  background-color: #e6e6e6;
  color: #000 !important;
}

.chart-info {
  pointer-events: none;
  align-self: flex-start;
  width: 100%;
  margin: 5px 0 10px;
}

.chart-info p {
  color: #faebd7;
  text-align: left;
  width: 100%;
  margin: 0;
}

.light .chart-info p, .light .chart-popup-date, .light .chart-popup-text, .light .chart-popup-num {
  color: #000;
}

.chart-anim-btns {
  cursor: pointer;
  pointer-events: all;
  border: 1px solid #faebd7;
  border-radius: 6px;
  align-self: flex-start;
  align-items: center;
  margin-top: 2px;
  margin-left: 10px;
  padding: 5px 10px;
}

.light .chart-anim-btns {
  border-color: #000;
}

.chart-anim-btns-change {
  background-color: #54cbf2 !important;
  border-color: #54cbf2 !important;
}

.light .chart-anim-btns-change {
  background-color: #038ebc !important;
  border-color: #038ebc !important;
}

.chart-anim-btns-change p {
  color: #000 !important;
}

.chart-anim-btns svg {
  width: 13px;
  height: 13px;
}

.light .chart-anim-btns svg path {
  fill: #171717;
}

.chart-anim-btns p {
  color: #faebd7;
  text-transform: uppercase;
  margin: 0 0 0 10px;
}

.light .chart-anim-btns p {
  color: #000;
}

#svgChart {
  position: relative;
}

.chart-text {
  fill: #faebd7;
  font-size: 1em;
}

.chart-lines {
  stroke: #faebd7;
}

.light .chart-lines {
  stroke: #000;
}

.light .chart-text {
  fill: #000;
}

.plot-lines {
  pointer-events: all;
}

.polyline {
  fill: none;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  pointer-events: none;
}

.hover-line, .chart-horiz-lines {
  stroke: #282828;
}

.hover-info {
  pointer-events: none;
}

.light .hover-line, .light .chart-horiz-lines {
  stroke: #e6e6e6;
}

.chart-popup {
  z-index: 9;
  pointer-events: none;
  white-space: nowrap;
  opacity: .9;
  color: #fff;
  text-align: left;
  background-color: #282828;
  border-radius: 4px;
  padding: .3em;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-100%, -100%);
  box-shadow: 0 2px 2px #00000059;
}

.light .chart-popup {
  background-color: #fff;
  border: 1px solid #e6e6e6;
}

.chart-popup-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.chart-popup-flex {
  margin: 2px 0;
}

.chart-popup-flex:nth-child(2n) {
  margin-left: 50px;
}

.chart-popup-date {
  color: #faebd7;
  margin: 0;
  font-size: 1em;
  font-weight: 600;
}

.chart-popup-text, .chart-popup-num {
  color: #faebd7;
  margin: 0 2px;
  font-weight: 100;
  display: inline-block;
}

.chart-popup-text {
  font-size: .9em;
}

.chart-popup-num {
  font-size: 1.5em;
}

.chart-popup-circle {
  width: 12px;
  height: 12px;
}

.overlay {
  height: calc(100 * var(--vh));
  z-index: 99;
  opacity: .7;
  -o-transition: .5s ease-in;
  background-color: #000;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: all .5s ease-in;
  display: flex;
  position: fixed;
}

.loader {
  display: inline-block;
  transform: translateZ(1px);
}

.loader > div {
  background: #54cbf2;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  margin: 8px;
  animation: 2.4s cubic-bezier(0, .2, .8, 1) infinite lds-circle;
  display: inline-block;
}

.light .loader > div {
  background: #3c3c3c;
}

@keyframes lds-circle {
  0%, 100% {
    animation-timing-function: cubic-bezier(.5, 0, 1, .5);
  }

  0% {
    transform: rotateY(0);
  }

  50% {
    animation-timing-function: cubic-bezier(0, .5, .5, 1);
    transform: rotateY(1800deg);
  }

  100% {
    transform: rotateY(3600deg);
  }
}

.overlay p {
  color: #fff;
  border-radius: 6px;
  margin: 0;
  padding: 10px;
  font-size: 1.5em;
  position: absolute;
}

.error {
  background-color: #a52432;
}

#toggleSideBar, #keyBtn, #centerBtn, #toggleDark {
  text-align: center;
  cursor: pointer;
  z-index: 8;
  -ms-touch-action: none;
  touch-action: none;
  opacity: 0;
  background-color: #faebd7;
  border-radius: 8px;
  width: 34px;
  height: 34px;
  padding: 5px;
  line-height: 20px;
  position: fixed;
}

.light #toggleSideBar, .light #keyBtn, .light #centerBtn, .light #toggleDark {
  background-color: #fff;
  box-shadow: 0 0 2px #000;
}

#toggleSideBar {
  opacity: 0;
  background-position: -68px 0;
  top: 60px;
  left: 12px;
}

#toggleDark {
  top: 60px;
  right: 12px;
}

#lightMode {
  display: none;
}

.light #lightMode {
  display: block;
}

.light #darkMode {
  display: none;
}

#centerBtn, #keyBtn {
  right: 12px;
}

#centerBtn {
  top: 100px;
}

#centerSVG path {
  fill: #282828;
}

#keyBtn {
  background-position: -34px 0;
  top: 140px;
}

#currentDataWrapper {
  z-index: 9;
  -ms-touch-action: none;
  touch-action: none;
  opacity: 0;
  position: fixed;
  top: 60px;
  right: 52px;
}

#globalHelpTip {
  left: 10px;
}

#globalHelpTip, #legendHelpTip {
  margin-top: -5px;
}

#globalHelpTip p, #legendHelpTip p {
  right: -4px;
  left: unset;
}

#globalHelpTip p:before, #legendHelpTip p:before {
  right: 10px;
  left: unset;
}

#currentData {
  color: #000;
  text-transform: uppercase;
  background-color: #54cbf2;
  border: 1px solid #282828;
  border-radius: 10px;
  padding: 10px;
  font-size: 2em;
  font-weight: 400;
}

#keys {
  color: #faebd7;
  z-index: 8;
  -ms-touch-action: none;
  touch-action: none;
  opacity: 0;
  background-color: #1d1d1d;
  border-radius: 8px;
  width: auto;
  margin: 0;
  padding: 20px 10px 20px 20px;
  position: fixed;
  top: 139px;
  right: 11px;
}

#legendHelpTip {
  display: block;
  position: absolute;
  left: -15px;
}

.light #keys {
  color: #000;
  background-color: #fff;
  box-shadow: 0 0 1px #000;
}

.closeBtn {
  cursor: pointer;
  border-radius: 7px;
  padding: 5px;
}

#closeKeys {
  float: right;
}

.light #closeKeys {
  color: #000;
}

h2 {
  text-transform: uppercase;
  margin: 0;
}

#keyTitle {
  margin: 0 0 25px;
}

.keys {
  flex: auto;
  padding: 0 20px 0 0;
  display: flex;
}

.colors {
  flex: 0 0 15px;
}

.colors-wrapper {
  width: 15px;
}

.color, .data-range {
  -o-transition: .1s ease-out;
  border: none;
  outline: none;
  height: 40px;
  margin: .5px 0;
  font-family: Gill Sans, sans-serif;
  transition: all .1s ease-out;
}

.color:last-child {
  border-bottom-right-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.on-color-hover {
  border: 1px solid #f9ebd7;
  transform: scale(1.2);
}

.light .on-color-hover {
  border: 1px solid #000;
}

.key-data {
  flex: auto;
}

.data-range {
  white-space: nowrap;
  color: #faebd7;
  background-color: #0000;
  align-items: center;
  padding: 0 0 0 15px;
  font-size: 1em;
  display: flex;
}

.light .data-range {
  color: #000;
}

.data-line, .data-line-small {
  display: none;
}

#sideBar {
  height: calc(100 * var(--vh));
  opacity: .9;
  -o-transition: transform .3s ease-in-out;
  z-index: 11;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  background-color: #1b1b1b;
  flex-direction: column;
  width: 300px;
  padding-bottom: 50px;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  top: 50px;
  overflow-y: scroll;
}

.light #sideBar {
  background-color: #002b6b;
}

.sideBar-header {
  border-bottom: 1px solid #fff;
  align-items: center;
  padding: 0 12px;
  display: flex;
}

.closeBtn {
  color: #fff;
  text-align: end;
  flex: 1;
}

#livePulse {
  background-color: #f44336;
  border-radius: 10px;
  width: 6px;
  height: 6px;
  animation: .8s ease-in-out infinite alternate pulse;
}

.pulse {
  color: #f44336;
  margin: 0 6px 0 0;
}

#timeStamp {
  margin: 0;
  font-size: 14px;
}

#switchWrapper {
  width: 100%;
  height: 148px;
}

.switch-titles {
  opacity: .4;
  cursor: pointer;
  font-size: 1.5em;
  font-weight: 100;
}

.switch-target-circles {
  opacity: 0;
  cursor: pointer;
}

#casesTitle {
  opacity: 1;
}

.switch-rect, .switch-titles {
  -o-transition: .15s ease-in;
  transition: all .15s ease-in;
}

#switchCircle {
  fill: #1e1e1e;
  -o-transition: .15s ease-in-out;
  transition: all .15s ease-in-out;
}

.light #switchCircle {
  fill: #002b6b;
}

.options-div {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  width: auto;
  height: 39px;
  min-height: 39px;
  margin-bottom: 0;
  padding: 0;
  overflow: hidden;
}

#dropDown {
  cursor: pointer;
  background-color: #faebd7;
  border-bottom: 1px solid #1d1d1d;
  justify-content: space-between;
  height: 40px;
  display: flex;
  position: sticky;
  top: -1px;
}

.light #dropDown {
  background-color: #fff;
  box-shadow: 0 0 3px #000;
}

.flex-span {
  -ms-grid-row-align: center;
  align-self: center;
  margin-left: 15px;
}

#dropDownTitle {
  color: #282828;
  font-weight: 400;
}

.chart-option-arrow {
  filter: invert();
}

.light .chart-option-arrow {
  filter: none;
}

.dropDown-arrow {
  opacity: .5;
  cursor: pointer;
  -o-transition: .1s ease-in;
  background-position: 0 0;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  transition: all .1s ease-in;
}

#testsMenu, #deathsMenu, #vaccMenu {
  display: none;
}

.cases-btns, .deaths-btns, .tests-btns, .vacc-btns, .chart-btns {
  color: #faebd7;
  text-align: left;
  -o-transition: .1s ease-in;
  cursor: pointer;
  background-color: #3d3c3a;
  border: none;
  outline: none;
  width: 100%;
  height: 40px;
  padding: 0 0 0 15px;
  font-family: Gill Sans, sans-serif;
  font-size: 1em;
  font-weight: 100;
  transition: all .1s ease-in;
  display: block;
}

.light .cases-btns, .light .deaths-btns, .light .tests-btns, .light .vacc-btns {
  color: #000;
  background-color: #fff;
}

.menu-btns-active {
  color: #000 !important;
}

.menu-btns-active.cases-btns {
  background-color: #54cbf2 !important;
}

.menu-btns-active.tests-btns {
  background-color: #f4bc68 !important;
}

.menu-btns-active.deaths-btns {
  background-color: #f6584c !important;
}

.menu-btns-active.vacc-btns {
  background-color: #4cf6af !important;
}

#vaccineList {
  z-index: 2;
  border-radius: 6px;
  width: 200px;
  max-height: 40px;
  position: absolute;
  top: 139px;
  right: 11px;
  overflow-y: auto;
  transform: translateX(250px);
}

.vaccine-list-change {
  transform: none !important;
}

.vaccine-list-open {
  max-height: 220px !important;
}

#vaccListHeader {
  cursor: pointer;
  background-color: #faebd7;
  justify-content: space-between;
  height: 40px;
  position: sticky;
  top: 0;
}

.light #vaccListHeader {
  color: #faebd7;
  background-color: #282828;
}

.light #vaccDropDownArrow {
  filter: invert();
}

.vacc-list-btns {
  color: #faebd7;
  text-align: left;
  -o-transition: .1s ease-in;
  cursor: pointer;
  background-color: #3d3c3a;
  width: 100%;
  height: 40px;
  padding: 0 0 0 15px;
  font-size: 1em;
  font-weight: 100;
  transition: all .1s ease-in;
}

#statsWrapper {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  flex: 1;
  overflow-y: scroll;
}

#worldStats, #worldList {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  border-top: 1px solid #fff;
  -ms-flex-negative: 1000;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  overflow: auto;
}

#worldStats {
  padding: 15px 12px 25px;
  overflow: hidden;
}

.worldStats-flex {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

#worldList {
  padding: 15px 0 25px;
}

#worldList h2, #worldList .ranks-title {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  padding: 0 12px;
}

.global-title {
  text-transform: uppercase;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  margin: 0;
  font-weight: 100;
}

.global-cases-title {
  color: #68d1f4;
}

.global-tests-title {
  color: #f4bc68;
}

.global-deaths-title {
  color: #f6584c;
}

.global-vacc-title {
  color: #4cf6af;
}

.stats {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  pointer-events: none;
  margin: 15px 0 0;
  font-size: 2em;
}

.stats-perc {
  font-size: 1em !important;
}

.stats-titles {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  pointer-events: none;
  margin: 0;
  font-size: 1em;
}

.stats-flex {
  cursor: pointer;
  color: #faebd7;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  align-self: flex-start;
  align-items: center;
  width: 100%;
  margin: 3px auto;
  padding: 0 12px;
  display: flex;
}

.stats-flex:hover {
  background-color: #79d7f4;
  color: #000 !important;
}

.ranks-title {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  margin: 0 0 20px;
  font-size: 1em;
}

.inline-count {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  flex: 0 0 30px;
  margin: 0;
  font-size: 1em;
}

.inline-flag {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  flex: 0 0 32px;
  width: 32px;
  height: 28px;
}

.inline-p {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  flex: 100%;
  margin: 0 10px;
  font-size: 1em;
}

.inline-stat {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  margin: 0;
  font-size: 1em;
}

#mapDiv, #usMap {
  height: calc(100 * var(--vh));
  z-index: 1;
  opacity: 0;
  width: 100%;
  margin: 0 auto;
  position: fixed;
}

svg {
  overflow: hidden;
}

.map-div path {
  fill: #fff;
  fill-rule: evenodd;
  stroke-width: calc(.1px / var(--zoom));
  -o-transition: fill .2s ease-in;
  cursor: pointer;
  transition: fill .2s ease-in;
}

.map-div path:hover {
  stroke-width: calc(2px / var(--zoom));
}

#worldMap, #usMap {
  stroke-linejoin: round;
  stroke: #fff;
  fill: none;
  stroke-width: .2px;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
  width: 100%;
  height: 100%;
  position: relative;
}

.light #worldMap, .light #usMap {
  stroke: #000;
}

#popup {
  text-align: center;
  z-index: 10;
  -ms-touch-action: none;
  touch-action: none;
  opacity: .9;
  pointer-events: none;
  background-color: #000;
  border-radius: 8px;
  padding: 10px;
  position: absolute;
}

.popup-flex {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.popup-big, .popup-small, .country {
  margin: 0;
  display: block;
}

.popup-big {
  margin-left: 15px;
  font-size: 1.5em;
  font-weight: 400;
}

.popup-vaccines {
  max-width: 200px;
}

.country {
  margin-bottom: 10px;
  font-size: 1.5em;
}

.flag {
  width: 40px;
  height: 40px;
  margin: 0 auto;
  display: block;
}

#about {
  height: calc(100 * var(--vh));
  z-index: 2;
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  user-select: text;
  -khtml-user-select: text;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  width: 100%;
  padding: 50px 5px 0;
  position: absolute;
  overflow-y: scroll;
}

#aboutWrapper {
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1100px;
  margin: 40px auto 0;
  padding-bottom: 50px;
  display: flex;
}

.about-flex {
  opacity: .9;
  text-align: center;
  background-color: #000;
  border-radius: 8px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: calc(50% - 10px);
  margin: 5px;
  padding: 20px;
  display: flex;
}

.about-flex-100 {
  width: calc(100% - 10px);
}

.about-flex-col, .about-flex-title {
  flex-direction: column;
  margin: 0 30px;
  display: flex;
}

.about-flex-col {
  width: 100%;
}

.light .about-flex {
  background-color: #fff;
}

.about-dark-blue {
  background-color: #002029 !important;
}

.about-flex a:link, .about-flex a:visited {
  color: #faebd7;
  cursor: pointer;
}

.light .about-flex a:link, .light .about-flex a:visited {
  color: #000;
  cursor: pointer;
}

#about h1 {
  color: #54cbf2;
  margin: 10px 0;
  font-size: 3em;
  font-weight: 100;
}

.light #about h1 {
  color: #f3564a;
}

.text {
  color: #faebd7;
  margin: 0 0 20px;
  font-size: 2em;
}

.small-text {
  color: #cacaca;
  margin: 0;
  font-size: 1.3em;
}

.smallest-text {
  color: #cacaca;
  margin: 0;
  font-size: .8em;
}

.light .text {
  color: #000;
}

.light .small-text {
  color: #545454;
}

.light .text-over-blue {
  color: #fff !important;
}

@media (max-width: 1024px) {
  #header h1 {
    font-size: 1em;
  }

  .about-flex {
    flex-direction: column;
    width: calc(100% - 10px);
  }

  #countryPopup {
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
  }

  .country-wrapper {
    min-height: unset;
    align-items: center;
    width: calc(100% - 5px);
    height: auto;
    padding: 10px;
    display: flex;
    position: relative;
    top: 0;
  }

  .stats-column {
    flex: 1;
    margin: 10px;
  }

  .stats-column-flex {
    width: calc(50% - 5px);
  }

  .flex-stats-container {
    margin-top: 0;
    margin-left: 0;
  }

  #chart {
    width: calc(100% - 5px);
  }

  .chart-change {
    height: calc(calc(100 * var(--vh))  - 60px) !important;
  }
}

@media (max-width: 768px) {
  .btn-container {
    margin: 0;
    padding-bottom: 20px;
  }

  #logo {
    margin-right: 10px;
  }

  #header h1 {
    flex: 1;
    font-size: 1em;
  }

  #searchWrapper {
    background-color: #0000;
    flex: 0;
    margin: 0 30px 0 0;
  }

  #searchInput {
    visibility: hidden;
  }

  #closeSearch {
    display: none;
  }

  #globalInstructions {
    top: 91px;
  }

  .country-wrapper {
    flex-wrap: wrap;
  }

  .country-container {
    flex-direction: column;
    -webkit-box-flex: 100%;
    flex: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .stats-column, .flex-stats-container {
    text-align: center;
  }

  .prop-title-main {
    width: auto !important;
  }

  .font-vw {
    font-size: 5vw;
  }

  #nav {
    display: none;
  }

  #hamburger {
    cursor: pointer;
    pointer-events: all;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    display: flex;
    position: absolute;
    top: 7px;
    right: 12px;
  }

  #line1, #line2 {
    -o-transition: .3s ease-in-out;
    background-color: #faebd7;
    border-radius: 50px;
    width: 25px;
    height: 2px;
    margin: 3px 0;
    transition: all .3s ease-in-out;
  }

  .change #line1 {
    transform: rotate(-45deg)translate(-1px, 5px);
  }

  .change #line2 {
    transform: rotate(45deg)translate(0, -5px);
  }

  .light #line1, .light #line2 {
    background-color: #000;
  }

  #mobileNav {
    -o-transition: transform .3s ease-out;
    z-index: 11;
    -ms-touch-action: none;
    touch-action: none;
    background-color: #000;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    transition: transform .3s ease-out;
    display: flex;
    position: absolute;
    top: 50px;
  }

  .light #mobileNav {
    background-color: #fff;
    box-shadow: 0 0 2px #000;
  }

  #mobileNav .menu-btns {
    text-align: center;
    width: 100%;
    margin: 0;
    padding: 15px 0;
  }

  #toggleSideBar, #centerBtn, #keyBtn, #toggleDark {
    z-index: 9;
    box-shadow: 0 0 2px #000;
    background-color: unset !important;
    top: 53.5px !important;
  }

  #keys {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    width: 100%;
    padding: 5px 10px 10px;
    bottom: 0;
    top: unset !important;
    right: unset !important;
  }

  #keys h2, #keyTitle {
    display: none;
  }

  #closeKeys {
    position: fixed;
    bottom: 55px;
    right: 5px;
    background-color: #1f1f1f !important;
  }

  .light #closeKeys {
    background-color: #fff !important;
  }

  .keys {
    flex-direction: column;
    padding: 0;
  }

  .colors-wrapper {
    z-index: 1;
    order: 2;
    width: 100%;
  }

  .flex-column {
    flex-direction: row-reverse;
  }

  .color {
    flex: auto;
    height: 15px;
    margin: 0 .5px;
  }

  .color:last-child {
    border-top-left-radius: 3px !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 3px !important;
  }

  .key-data {
    align-self: flex-start;
    width: 100%;
    margin-left: 0;
  }

  .data-range:last-child {
    white-space: unset;
    padding: 5px;
  }

  .data-range {
    -o-transform: translateX(var(--trans));
    -moz-transform: translateX(var(--trans));
    -ms-transform: translateX(var(--trans));
    -webkit-transform: translateX(var(--trans));
    transform: translateX(var(--trans));
    flex-direction: column;
    flex: auto;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
    margin: 0 .5px;
    padding: 0 5px;
    font-size: 12px;
    display: flex;
  }

  .data-line, .data-line-small {
    background-color: #505050;
    width: 1px;
    display: block;
  }

  .data-line {
    height: 20px;
  }

  .data-line-small {
    height: 5px;
  }

  .light .data-line, .light .data-line-small {
    background-color: #e6e6e6;
  }

  #sideBar {
    opacity: 1;
    z-index: 15;
    width: 100%;
    top: 0;
  }

  #toggleSideBar {
    left: 5px;
  }

  #keyBtn {
    right: 5px;
  }

  #currentDataWrapper {
    z-index: 8;
    width: 100%;
    height: 41px;
    top: 50px;
    right: 0;
  }

  #globalHelpTip {
    top: 70px;
    left: unset;
    margin-top: 0;
    position: absolute;
    right: 10px;
  }

  #legendHelpTip {
    top: -14px;
    left: 5px;
  }

  #legendHelpTip p {
    right: unset;
    top: -78px;
  }

  #legendHelpTip p:before {
    bottom: -12px;
    top: unset;
    border-top-color: #faebd7;
    border-bottom-color: #0000;
    left: 10px !important;
  }

  .light #legendHelpTip p:before {
    border-top-color: #282828;
  }

  #currentData {
    text-align: center;
    opacity: .9;
    border-radius: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 100px;
    font-size: .8em;
    display: inline-flex;
  }

  #toggleDark {
    left: 47px;
  }

  #centerBtn {
    right: 47px;
  }

  #sideBar {
    padding-bottom: 0;
  }

  #aboutWrapper {
    margin-top: 10px;
  }

  .chart-header {
    padding-bottom: 20px;
  }

  #globalHelpTip p, #legendHelpTip p {
    width: 300px;
  }

  .help-tip p {
    right: -4px;
    left: unset;
    pointer-events: none;
    width: 200px;
  }

  .help-tip p:before {
    right: 10px;
    left: unset;
  }

  .help-tip p:after {
    height: 0;
  }

  .chart-popup-wrapper {
    flex-direction: column;
    margin: 5px 0;
  }

  .chart-popup-flex {
    width: 100%;
    margin: 0 !important;
  }

  #vaccineList {
    top: 95px;
    right: 2px;
  }
}

@media (max-width: 520px) {
  #countryPopup {
    padding: 0;
  }

  .stats-column-flex {
    width: calc(100% - 5px);
  }

  .font-vw {
    font-size: 7.5vw;
  }

  #header h1 {
    font-size: 14px;
  }

  .country-big {
    text-align: center;
  }
}

@media (max-width: 400px) {
  .chart-anim-btns p {
    display: none;
  }
}

@media (hover: hover) {
  .menu-btns:hover, .menu-btns:focus, .menu-btns:active {
    color: #79d7f4 !important;
  }

  .light .menu-btns:hover, .light .menu-btns:focus, .light .menu-btns:active {
    color: #9c9c9c !important;
  }

  #closeSearch:hover, #closePopup:hover {
    opacity: .7;
  }

  .us-btn:hover, .us-btn:focus {
    text-decoration: underline;
    box-shadow: 0 1px 10px #9a2532;
  }

  .chart-btns:hover, .chart-btns:focus {
    color: #000 !important;
    background-color: #96dcf4 !important;
  }

  .chart-anim-btns:hover svg path {
    fill: #000;
  }

  .chart-anim-btns:hover {
    background-color: #54cbf2;
    border-color: #54cbf2;
  }

  .light .chart-anim-btns:hover {
    background-color: #038ebc;
    border-color: #038ebc;
  }

  .chart-anim-btns:hover p {
    color: #000;
  }

  #toggleSideBar:hover, #centerBtn:hover, #keyBtn:hover, #toggleDark:hover {
    background-color: #cacaca;
  }

  #closeKeys:hover {
    background-color: #3b3a38;
    color: unset !important;
  }

  .light #closeKeys:hover {
    background-color: #cacaca;
    color: unset !important;
  }

  .closeBtn:hover {
    color: #626262;
  }

  .light .closeBtn:hover {
    color: #cacaca;
  }

  .dropDown-arrow:hover, .dropDown-arrow:active, .dropDown-arrow:focus {
    opacity: 1;
  }

  .cases-btns:hover {
    color: #000;
    background-color: #96dcf4;
  }

  .tests-btns:hover {
    color: #000;
    background-color: #f9d7a4;
  }

  .deaths-btns:hover {
    color: #000;
    background-color: #ff9890;
  }

  .vacc-btns:hover, .vacc-list-btns:hover {
    color: #000;
    background-color: #bafbdf;
  }

  .about-flex a:hover, .about-flex a:focus, .light .about-flex a:hover, .light .about-flex a:focus {
    color: #545454;
    text-decoration: none;
  }
}

/*# sourceMappingURL=index.703f64d6.css.map */
