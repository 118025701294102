/*  BEGIN KO-FI BUTTON */
.btn-container {
    visibility: hidden;
}

.btn-container a {
    background-color: #a52432 !important;
    -webkit-box-shadow: 0px 1px 5px rgba(165, 36, 50, 0.7) !important;
    box-shadow: 0px 1px 5px rgba(165, 36, 50, 0.7) !important;
    font-family: 'Gill Sans', sans-serif !important;
    text-transform: uppercase;
    border-radius: 6px !important;
}

/*  END KO-FI BUTTON */
/* BEGIN GLOBALS */
@font-face {
    font-family: 'Gill Sans';
    src: url(https://worldmapcovid19.b-cdn.net/static/fonts/GillSans-Regular.ttf) format("truetype");
    ;
    font-weight: 400;
}

@font-face {
    font-family: 'Gill Sans';
    src: url(https://worldmapcovid19.b-cdn.net/static/fonts/GillSans-Light.ttf) format("truetype");
    ;
    font-weight: 100;
}

@font-face {
    font-family: 'Gill Sans';
    src: url(https://worldmapcovid19.b-cdn.net/static/fonts/GillSans-SemiBold.ttf) format("truetype");
    ;
    font-weight: 600;
}

@-o-keyframes pulse {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
    }
}

@-moz-keyframes pulse {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
    }
}

@-webkit-keyframes pulse {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
    }
}

@keyframes pulse {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 100%;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 100%;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 100%;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 100%;
    }
}

*,
:after,
:before {
    box-sizing: border-box;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

*::-webkit-scrollbar {
    display: none;
}

.red {
    color: #f6584C !important;
}

.light .red {
    color: #B13507 !important;
}

.blue {
    color: #54cbf2 !important;
}

.white {
    color: #faebd7 !important;
}

.black {
    color: #000 !important;
}

.gray {
    color: #cacaca !important;
}

.dark-gray {
    color: #757575 !important;
}

.yellow,
.yellow-test {
    color: #ffc82a !important;
}

.light .yellow-test {
    color: #967000 !important;
}

.green {
    color: #4cf6af !important;
}

.light .green {
    color: #33a976 !important;
}

.dark-path {
    fill: #000 !important;
}

.light-path {
    fill: #fff !important;
}

.highlight-path {
    stroke-width: calc(0.5px/var(--zoom)) !important;
}

.light .dark-path {
    fill: #fff !important;
}

.light .light-path {
    fill: #000 !important;
}

.stroke-gray {
    stroke: #282828;
}

.fill-gray {
    fill: #282828;
}

.light .stroke-gray {
    stroke: #e6e6e6;
}

.light .fill-gray {
    fill: #e6e6e6;
}

.text-center {
    text-align: center !important;
}

.ease-out {
    -o-transition: 0.3s ease-out;
    -moz-transition: 0.3s ease-out;
    -webkit-transition: 0.3s ease-out;
    transition: 0.3s ease-out;
}

.ease-in-out {
    -o-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
}

.transform {
    -o-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

.transform-y-178 {
    -o-transform: translateY(-178px);
    -moz-transform: translateY(-178px);
    -webkit-transform: translateY(-178px);
    -ms-transform: translateY(-178px);
    transform: translateY(-178px);
}

.transform-y-260 {
    -o-transform: translateY(-260px);
    -moz-transform: translateY(-260px);
    -webkit-transform: translateY(-260px);
    -ms-transform: translateY(-260px);
    transform: translateY(-260px);
}

.transform-y-30 {
    -o-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
}

.transform-y-50 {
    -o-transform: translateY(-50px);
    -moz-transform: translateY(-50px);
    -webkit-transform: translateY(-50px);
    -ms-transform: translateY(-50px);
    transform: translateY(-50px);
}

.transform-rotate {
    -o-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.flex-column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.no-display {
    display: none !important;
}

.no-visibility {
    visibility: hidden !important;
}

.bckg-sprite {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(https://worldmapcovid19.b-cdn.net/static/images/icons_sprites.png);
}

.bckg-img {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.full-opacity {
    opacity: 1 !important;
    margin: 0 !important;
    padding: 0 !important;
    -o-transition: none !important;
    -moz-transition: none !important;
    -webkit-transition: none !important;
    transition: none !important;
}

.btns {
    border: none;
    outline: none;
    background: none;
    padding: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    font-family: 'Gill Sans', sans-serif;
}

/* END GLOBALS */
html,
body {
    margin: 0;
    padding: 0;
    font-family: 'Gill Sans', sans-serif;
}

html {
    background-color: #282828;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-size-adjust: 100%;
    -webkt-scroll-behavior: smooth;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    overflow-y: hidden;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.light {
    background-color: #e6e6e6;
}

body {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}

.flex-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

/* begin header */
#header {
    position: fixed;
    width: 100%;
    height: 50px;
    opacity: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    background-color: #000;
    padding: 0 12px;
    z-index: 12;
    box-shadow: 0px 0px 2px 0px black;
    -ms-touch-action: none;
    touch-action: none;
}

.light #header {
    background-color: #fff;
}

#logo {
    width: 35px;
    height: 35px;
    margin-right: 10px;
}

.light #logo path {
    fill: #013544;
}

#header h1 {
    font-weight: 100;
    font-size: 1.5em;
}

.light #header h1 {
    color: #000 !important;
}

#nav {
    pointer-events: all;
}

.menu-btns {
    cursor: pointer;
    margin: 0;
    color: #faebd7;
}

.light .menu-btns {
    color: #000;
}

#worldBtn,
#worldBtnMobile {
    color: #54cbf2;
}

#usBtn {
    margin-left: 20px;
}

#aboutBtn {
    text-decoration: none;
    margin: 0 20px;
}

/* begin hamburger */
#hamburger,
#mobileNav {
    display: none;
}

/* end hamburger */
/* begin search */
@media(min-width:769px) {
    #searchWrapper {
        -webkit-box-flex: 1 !important;
        -ms-flex: 1 !important;
        flex: 1 !important;
        background-color: #282828 !important;
        margin-right: 20px !important;
    }

    .light #searchWrapper {
        background-color: #e6e6e6 !important;
    }

    #header h1,
    #closeSearch {
        display: block !important;
    }

    #searchInput {
        visibility: visible !important;
    }
}

.flex-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 !important;
    flex: 1 !important;
}

#searchWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0px 20px;
    border-radius: 5px;
    background-color: #282828;
    pointer-events: all;
}

#searchIcon {
    width: 25px;
    height: 25px;
    margin: 0 5px;
    fill: #faebd7;
}

.light #searchIcon {
    fill: #000;
}

#searchInput {
    width: calc(100% - 68px);
    height: 30px;
    border: none;
    outline: none;
    background-color: inherit;
    color: #faebd7;
}

.light #searchInput {
    color: #282828;
}

#closeSearch {
    width: 25px;
    height: 25px;
    margin: 0 4px;
    border: none;
    outline: none;
    cursor: pointer;
    visibility: hidden;
    fill: #faebd7;
}

.light #closeSearch {
    fill: #282828;
}

#resultsWrapper {
    width: auto;
    max-height: 250px;
    overflow-y: auto;
    position: fixed;
    top: 50px;
    border-radius: 0 0 8px 8px;
    padding: 10px 0;
    z-index: 10;
    background-color: #1b1b1b;
    visibility: hidden;
    -ms-touch-action: none;
    touch-action: none;
}

.light #resultsWrapper {
    background-color: #e6e6e6;
    box-shadow: 0px 0px 2px 0px black;
}

.results-flex {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 5px 10px;
    color: #faebd7;
    cursor: pointer;
}

.light .results-flex {
    color: #000;
}

.results-flex-focus {
    background-color: #79d7f4;
    color: #000 !important;
}

.results-flex:hover {
    background-color: #79d7f4;
}

.results-flex:hover .inline-p {
    color: #000 !important;
}

/* end search */
/* end header */
#globalInstructions {
    position: absolute;
    top: 50px;
    width: 100%;
    color: #faebd7;
    text-align: center;
    background-color: #000;
    opacity: 0.5;
    font-size: 0.8em;
    z-index: 2;
    -ms-touch-action: none;
    touch-action: none;
}

#globalInstructions p {
    margin: 5px 2px;
}

/* begin country popup */
#closePopup {
    width: 20px;
    height: 20px;
    border: none;
    outline: none;
    cursor: pointer;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    z-index: 10;
    margin-left: -30px;
    margin-right: 10px;
    visibility: hidden;
    pointer-events: all;
}

.closepopup-change {
    visibility: visible !important;
    margin-left: -5px !important;
}

#closePopup polyline {
    stroke: #fff;
}

.light #closePopup polyline {
    stroke: #000;
}

#countryPopup {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: auto;
    margin: 50px auto;
    padding: 0px 10px;
    background-color: #282828;
    z-index: 8;
    opacity: 0.9;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

.light #countryPopup {
    background-color: #cacaca;
}

.country-wrapper {
    position: -webkit-sticky;
    position: sticky;
    top: 5px;
    min-height: calc(calc(100 * var(--vh)) - 60px);
    padding: 0 10px 100px;
    margin: 5px 0;
    background-color: #1b1b1b;
    border-radius: 8px;
}

.light .country-wrapper {
    background-color: #e6e6e6;
}

.country-container {
    display: block;
    margin: 0 5px 20px;
    color: #faebd7;
}

.light .country-container {
    color: #000;
}

.us-btn {
    border: none;
    outline: none;
    text-decoration: none;
    position: relative;
    top: 10px;
    font-family: 'Gill Sans', sans-serif;
    font-size: 1.5em;
    font-weight: 100;
    text-transform: uppercase;
    color: #e9e9e9;
    background-color: #a52432;
    box-shadow: 0px 1px 5px 0px #9a2532;
    padding: 5px 10px;
    border-radius: 8px;
    cursor: pointer;
}

.flag-big {
    width: 120px;
    height: 120px;
    pointer-events: none;
}

.country-big {
    font-size: 2em;
    margin: 0;
    pointer-events: none;
}

.stats-column {
    min-width: 220px;
    margin: 10px 0;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #fff;
    background-color: #000;
    pointer-events: none;
}

.prop-title,
.prop-title-main {
    width: calc(100% - 40px);
    margin: 0;
    font-size: 1.5em;
    text-transform: uppercase;
    font-weight: 100;
    color: #54cbf2;
    pointer-events: none;
}

.light .prop-title,
.light .prop-title-main .light .chart-header {
    color: #038ebc;
}

.flex-stats-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 2.5px;
    margin-left: 0.5%;
    padding-bottom: 75px;
}

.stats-column-flex {
    position: relative;
    width: calc(33% - 5px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 2.5px;
    padding: 20px;
    border-radius: 8px;
    background-color: #000;
}

.light .stats-column-flex,
.light #chart {
    background-color: #fff;
}

.stats-column-flex-100 {
    width: calc(99% - 5px);
}

.help-tip-stats {
    position: absolute !important;
    top: 10px !important;
    right: 10px !important;
}

.help-tip-stats p {
    width: 250px !important;
    right: -4px !important;
    left: unset !important;
}

.help-tip-stats p::before {
    right: 10px !important;
    left: unset !important;
}

.help-tip-stats p:after {
    height: 0;
}

.help-tip-chart {
    top: -10px !important;
    ;
    right: -10px !important;
    pointer-events: all;
}

.flex-stat {
    display: -webkit-box;
    ;
    display: -ms-flexbox;
    ;
    display: flex;
    ;
    -webkit-box-orient: vertical;
    ;
    -webkit-box-direction: normal;
    ;
    -ms-flex-direction: column;
    ;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 20px;
    color: #faebd7;
    pointer-events: none;
}

.light .flex-stat {
    color: #000;
}

.flex-stat-global {
    width: 60px !important;
    height: 70px !important;
    margin: 0 !important;
}

.pie-wrapper {
    width: 100px;
    height: 100px;
    pointer-events: none;
}

.pie-wrapper-global {
    width: 60px !important;
    height: 70px !important;
    margin-top: 0 !important;
}

.circle {
    -o-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
    width: 100%;
    height: 86px;
}

.circle-info {
    color: #6dff71;
    position: relative;
    top: -2.3em;
    left: 0;
    right: 0;
    text-align: center;
}

.circle-perc {
    top: -3.5em;
}

/* begin country chart */
#chart {
    position: relative;
    width: calc(99% - 5px);
    height: 100px;
    min-height: unset;
    margin: 2.5px 0px;
    padding: 20px;
    border-radius: 8px;
    background-color: #000;
    opacity: 1;
    pointer-events: none;
}

.chart-change {
    min-height: 350px !important;
    height: calc(calc(75 * var(--vh)) - 60px) !important;
}

.chart-change-nodata {
    min-height: 115px !important;
    height: 115px !important;
}

#chartLoader {
    position: absolute;
    display: inline-block;
    width: 80px;
    height: 80px;
    top: 15px;
    left: calc(50% - 40px);
    z-index: 99;
}

.chart-loader div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.chart-loader div:nth-child(2) {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}

.light .chart-loader div {
    border-color: #171717;
}

@-webkit-keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

.chart-no-data {
    text-align: center;
    margin-top: 15px;
    font-weight: 100;
}

.chart-header {
    position: absolute;
    width: calc(100% - 40px);
    color: #54cbf2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 10px;
}

.chart-header h2 {
    font-weight: 100;
    width: auto;
    max-width: calc(100% - 36px);
    text-align: left;
    margin-right: 10px;
}

/* begin chart info tooltip */
.help-tip {
    font-family: 'Gill Sans', sans-serif;
    border: none;
    outline: none;
    display: inline-block;
    position: relative;
    top: -1px;
    left: unset;
    text-align: center;
    background-color: #faebd7;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    font-size: 14px;
    line-height: 24px;
    padding: 0 !important;
    cursor: default;
}

.help-tip:before {
    font-family: serif;
    content: 'i';
    font-style: italic;
    font-weight: bold;
    font-size: 1.3em;
    color: #000;
}

.light .help-tip:before {
    color: #faebd7;
}

.help-tip:hover p {
    display: block;
    transform-origin: 100% 0%;
    -o-animation: fadeIn 0.3s ease-in-out;
    -moz-animation: fadeIn 0.3s ease-in-out;
    -webkit-animation: fadeIn 0.3s ease-in-out;
    animation: fadeIn 0.3s ease-in-out;
}

.help-tip p {
    /* The tooltip */
    display: none;
    text-align: left;
    background-color: #faebd7;
    padding: 20px;
    width: 300px;
    position: absolute;
    border-radius: 3px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    left: -4px;
    color: #000;
    font-size: 13px;
    line-height: 1.4;
    z-index: 11;
    margin: 8px 0px;
}

.light .help-tip p,
.light .help-tip {
    background-color: #282828;
    color: #faebd7;
}

.light .help-tip p::before {
    border-bottom-color: #282828;
}

.help-tip p:before {
    /* The pointer of the tooltip */
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-bottom-color: #faebd7;
    left: 10px;
    top: -12px;
}

.help-tip p:after {
    /* Prevents the tooltip from being hidden */
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
}

/* end chart info tooltip */
.chart-options-wrapper {
    position: relative;
    z-index: 10;
    width: 175px;
    height: 30px;
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 0px;
    border-radius: 8px;
    margin: 0;
    -o-transition: height .3s ease-out;
    -moz-transition: height .3s ease-out;
    -webkit-transition: height .3s ease-out;
    transition: height .3s ease-out;
    pointer-events: all;
}

.light .chart-options-wrapper {
    border: 1px solid #000;
}

.chart-dropdown {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 30px;
    background-color: #282828;
    border-bottom: 1px solid #1d1d1d;
    color: #fff;
    cursor: pointer;
}

.light .chart-dropdown {
    background-color: #e6e6e6;
    color: #000;
}

.chart-btns {
    color: #faebd7 !important;
    height: 30px !important;
}

.light .chart-btns {
    background-color: #e6e6e6;
    color: #000 !important;
}

.chart-info {
    width: 100%;
    -ms-flex-item-align: start;
    align-self: flex-start;
    margin: 5px 0px 10px;
    pointer-events: none;
}

.chart-info p {
    width: 100%;
    margin: 0px;
    color: #faebd7;
    text-align: left;
}

.light .chart-info p,
.light .chart-popup-date,
.light .chart-popup-text,
.light .chart-popup-num {
    color: #000;
}

.chart-anim-btns {
    padding: 5px 10px;
    margin-left: 10px;
    margin-top: 2px;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #faebd7;
    cursor: pointer;
    pointer-events: all;
}

.light .chart-anim-btns {
    border-color: #000;
}

.chart-anim-btns-change {
    background-color: #54cbf2 !important;
    border-color: #54cbf2 !important;
}

.light .chart-anim-btns-change {
    background-color: #038ebc !important;
    border-color: #038ebc !important;
}

.chart-anim-btns-change p {
    color: #000 !important;
}

.chart-anim-btns svg {
    width: 13px;
    height: 13px;
}

.light .chart-anim-btns svg path {
    fill: #171717;
}

.chart-anim-btns p {
    margin: 0 0 0 10px;
    color: #faebd7;
    text-transform: uppercase;
}

.light .chart-anim-btns p {
    color: #000;
}

#svgChart {
    position: relative;
}

.chart-text {
    fill: #faebd7;
    font-size: 1em;
}

.chart-lines {
    stroke: #faebd7;
}

.light .chart-lines {
    stroke: #000;
}

.light .chart-text {
    fill: #000;
}

.plot-lines {
    pointer-events: all;
}

.polyline {
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    pointer-events: none;
}

.hover-line,
.chart-horiz-lines {
    stroke: #282828;
}

.hover-info {
    pointer-events: none;
}

.light .hover-line,
.light .chart-horiz-lines {
    stroke: #e6e6e6;
}

.chart-popup {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    pointer-events: none;
    white-space: nowrap;
    padding: 0.3em;
    border-radius: 4px;
    background-color: #282828;
    opacity: 0.9;
    color: #fff;
    text-align: left;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.35);
    -o-transform: translate(-100%, -100%);
    -moz-transform: translate(-100%, -100%);
    -ms-transform: translate(-100%, -100%);
    -webkit-transform: translate(-100%, -100%);
    transform: translate(-100%, -100%);
}

.light .chart-popup {
    background-color: #fff;
    border: 1px solid #e6e6e6;
}

.chart-popup-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.chart-popup-flex {
    margin: 2px 0;
}

.chart-popup-flex:nth-child(2n) {
    margin-left: 50px;
}

.chart-popup-date {
    margin: 0;
    font-size: 1em;
    font-weight: 600;
    color: #faebd7;
}

.chart-popup-text,
.chart-popup-num {
    display: inline-block;
    margin: 0 2px;
    font-weight: 100;
    color: #faebd7;
}

.chart-popup-text {
    font-size: 0.9em;
}

.chart-popup-num {
    font-size: 1.5em;
}

.chart-popup-circle {
    width: 12px;
    height: 12px;
}

/* end country chart */
/* end country popup */
/* begin loader overlay */
.overlay {
    position: fixed;
    width: 100%;
    height: calc(100 * var(--vh));
    background-color: #000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 99;
    opacity: 0.7;
    -o-transition: 0.5s ease-in;
    -moz-transition: 0.5s ease-in;
    -webkit-transition: 0.5s ease-in;
    transition: 0.5s ease-in;
}

.loader {
    display: inline-block;
    transform: translateZ(1px);
}

.loader>div {
    display: inline-block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    background: #54cbf2;
    animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.light .loader>div {
    background: #3c3c3c;
}

@keyframes lds-circle {

    0%,
    100% {
        animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }

    0% {
        transform: rotateY(0deg);
    }

    50% {
        transform: rotateY(1800deg);
        animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }

    100% {
        transform: rotateY(3600deg);
    }
}

.overlay p {
    position: absolute;
    font-size: 1.5em;
    color: #fff;
    border-radius: 6px;
    padding: 10px;
    margin: 0;
}

.error {
    background-color: #a52432;
}

/* end loader overlay */
#toggleSideBar,
#keyBtn,
#centerBtn,
#toggleDark {
    width: 34px;
    height: 34px;
    position: fixed;
    padding: 5px;
    border-radius: 8px;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    z-index: 8;
    background-color: #faebd7;
    -ms-touch-action: none;
    touch-action: none;
    opacity: 0;
}

.light #toggleSideBar,
.light #keyBtn,
.light #centerBtn,
.light #toggleDark {
    background-color: #fff;
    box-shadow: 0px 0px 2px 0px black;
}

#toggleSideBar {
    background-position: -68px 0px;
    top: 60px;
    left: 12px;
    opacity: 0;
}

#toggleDark {
    top: 60px;
    right: 12px;
}

#lightMode {
    display: none;
}

.light #lightMode {
    display: block;
}

.light #darkMode {
    display: none;
}

#centerBtn,
#keyBtn {
    right: 12px;
}

#centerBtn {
    top: 100px;
}

#centerSVG path {
    fill: #282828;
}

#keyBtn {
    top: 140px;
    ;
    background-position: -34px 0px;
}

#currentDataWrapper {
    position: fixed;
    top: 60px;
    right: 52px;
    z-index: 9;
    -ms-touch-action: none;
    touch-action: none;
    opacity: 0;
}

#globalHelpTip {
    left: 10px;
}

#globalHelpTip,
#legendHelpTip {
    margin-top: -5px;
}

#globalHelpTip p,
#legendHelpTip p {
    right: -4px;
    left: unset;
}

#globalHelpTip p:before,
#legendHelpTip p:before {
    right: 10px;
    left: unset;
}

#currentData {
    font-size: 2em;
    font-weight: 400;
    color: #000;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #282828;
    background-color: #54cbf2;
    text-transform: uppercase;
}

#keys {
    position: fixed;
    top: 139px;
    right: 11px;
    width: auto;
    border-radius: 8px;
    padding: 20px 10px 20px 20px;
    margin: 0;
    background-color: #1d1d1d;
    color: #faebd7;
    z-index: 8;
    -ms-touch-action: none;
    touch-action: none;
    opacity: 0;
}

#legendHelpTip {
    display: block;
    position: absolute;
    left: -15px;
}

.light #keys {
    background-color: #fff;
    color: #000;
    box-shadow: 0px 0px 1px 0px black;
}

.closeBtn {
    cursor: pointer;
    padding: 5px;
    border-radius: 7px;
}

#closeKeys {
    float: right;
}

.light #closeKeys {
    color: #000;
}

h2 {
    margin: 0;
    text-transform: uppercase;
}

#keyTitle {
    margin: 0 0 25px;
}

.keys {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0px 20px 0px 0px;
}

.colors {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 15px;
    flex: 0 0 15px;
}

.colors-wrapper {
    width: 15px;
}

.color,
.data-range {
    font-family: 'Gill Sans', sans-serif;
    border: none;
    outline: none;
    height: 40px;
    margin: 0.5px 0px;
    -o-transition: 0.1s ease-out;
    -moz-transition: 0.1s ease-out;
    -webkit-transition: 0.1s ease-out;
    transition: 0.1s ease-out;
}

.color:last-child {
    border-bottom-right-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
}

.on-color-hover {
    border: 1px solid #f9ebd7;
    -o-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}

.light .on-color-hover {
    border: 1px solid #000;
}

.key-data {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.data-range {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    white-space: nowrap;
    padding: 0 0 0 15px;
    background-color: transparent;
    color: #faebd7;
    font-size: 1em;
}

.light .data-range {
    color: #000;
}

.data-line,
.data-line-small {
    display: none;
}

/* begin sideBar */
#sideBar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 300px;
    height: calc(100 * var(--vh));
    background-color: #1b1b1b;
    opacity: 0.9;
    position: fixed;
    top: 50px;
    overflow-y: scroll;
    -o-transition: transform 0.3s ease-in-out;
    -moz-transition: transform 0.3s ease-in-out;
    -webkit-transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    z-index: 11;
    padding-bottom: 50px;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

.light #sideBar {
    background-color: #002b6b;
}

.sideBar-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    padding: 0px 12px;
    border-bottom: 1px solid #fff;
}

.closeBtn {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    color: #fff;
    text-align: end;
}

#livePulse {
    width: 6px;
    height: 6px;
    border-radius: 10px;
    background-color: #f44336;
    -o-animation: pulse 0.8s ease-in-out 0s infinite alternate;
    -moz-animation: pulse 0.8s ease-in-out 0s infinite alternate;
    -webkit-animation: pulse 0.8s ease-in-out 0s infinite alternate;
    animation: pulse 0.8s ease-in-out 0s infinite alternate;
}

.pulse {
    margin: 0px 6px 0 0;
    color: #f44336;
}

#timeStamp {
    margin: 0;
    font-size: 14px;
}

/* begin switch */
#switchWrapper {
    width: 100%;
    height: 148px;
}

.switch-titles {
    font-size: 1.5em;
    font-weight: 100;
    opacity: 0.4;
    cursor: pointer;
}

.switch-target-circles {
    opacity: 0;
    cursor: pointer;
}

#casesTitle {
    opacity: 1;
}

.switch-rect,
.switch-titles {
    -o-transition: 0.15s ease-in;
    -moz-transition: 0.15s ease-in;
    -webkit-transition: 0.15s ease-in;
    transition: 0.15s ease-in;
}

#switchCircle {
    fill: #1e1e1e;
    -o-transition: 0.15s ease-in-out;
    -moz-transition: 0.15s ease-in-out;
    -webkit-transition: 0.15s ease-in-out;
    transition: 0.15s ease-in-out;
}

.light #switchCircle {
    fill: #002b6b;
}

/* end switch */
/* begin dropdown */
.options-div {
    width: auto;
    height: 39px;
    min-height: 39px;
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 0px;
    margin-bottom: 0px;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

#dropDown {
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 40px;
    background-color: #faebd7;
    border-bottom: 1px solid #1d1d1d;
    cursor: pointer;
}

.light #dropDown {
    box-shadow: 0px 0px 3px 0px black;
    background-color: #fff;
}

.flex-span {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    margin-left: 15px;
}

#dropDownTitle {
    font-weight: 400;
    color: #282828;
}

.chart-option-arrow {
    filter: invert(1);
}

.light .chart-option-arrow {
    filter: none;
}

.dropDown-arrow {
    width: 30px;
    height: 30px;
    background-position: 0px 0px;
    margin-right: 5px;
    opacity: 0.5;
    cursor: pointer;
    -o-transition: 0.1s ease-in;
    -moz-transition: 0.1s ease-in;
    -webkit-transition: 0.1s ease-in;
    transition: 0.1s ease-in;
}

#testsMenu,
#deathsMenu,
#vaccMenu {
    display: none;
}

.cases-btns,
.deaths-btns,
.tests-btns,
.vacc-btns,
.chart-btns {
    font-family: 'Gill Sans', sans-serif;
    display: block;
    width: 100%;
    height: 40px;
    outline: none;
    border: none;
    background-color: #3d3c3a;
    color: #faebd7;
    font-size: 1em;
    font-weight: 100;
    text-align: left;
    -o-transition: 0.1s ease-in;
    -moz-transition: 0.1s ease-in;
    -webkit-transition: 0.1s ease-in;
    transition: 0.1s ease-in;
    cursor: pointer;
    padding: 0 0 0 15px;
}

.light .cases-btns,
.light .deaths-btns,
.light .tests-btns,
.light .vacc-btns {
    background-color: #fff;
    color: #000;
}

.menu-btns-active {
    color: #000 !important;
}

.menu-btns-active.cases-btns {
    background-color: #54cbf2 !important;
}

.menu-btns-active.tests-btns {
    background-color: #f4bc68 !important;
}

.menu-btns-active.deaths-btns {
    background-color: #f6584c !important;
}

.menu-btns-active.vacc-btns {
    background-color: #4cf6af !important;
}

/* begin vaccines used dropdown */
#vaccineList {
    position: absolute;
    z-index: 2;
    width: 200px;
    max-height: 40px;
    top: 139px;
    right: 11px;
    -o-transform: translateX(250px);
    -moz-transform: translateX(250px);
    -webkit-transform: translateX(250px);
    -ms-transform: translateX(250px);
    transform: translateX(250px);
    border-radius: 6px;
    overflow-y: auto;
}

.vaccine-list-change {
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
}

.vaccine-list-open {
    max-height: 220px !important;
}

#vaccListHeader {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 40px;
    background-color: #faebd7;
    cursor: pointer;
}

.light #vaccListHeader {
    background-color: #282828;
    color: #faebd7;
}

.light #vaccDropDownArrow {
    filter: invert(1);
}

.vacc-list-btns {
    width: 100%;
    height: 40px;
    background-color: #3d3c3a;
    color: #faebd7;
    font-size: 1em;
    font-weight: 100;
    text-align: left;
    -o-transition: 0.1s ease-in;
    -moz-transition: 0.1s ease-in;
    -webkit-transition: 0.1s ease-in;
    transition: 0.1s ease-in;
    cursor: pointer;
    padding: 0 0 0 15px;
}

/* end vaccines used dropdown */
/* end dropdown */
/* begin world stats */
#statsWrapper {
    overflow-y: scroll;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

#worldStats,
#worldList {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-negative: 1000;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    overflow: auto;
    border-top: 1px solid #fff;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

#worldStats {
    padding: 15px 12px 25px;
    overflow: hidden;
}

.worldStats-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

#worldList {
    padding: 15px 0px 25px;
}

#worldList h2,
#worldList .ranks-title {
    padding: 0 12px;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

.global-title {
    margin: 0;
    text-transform: uppercase;
    font-weight: 100;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

.global-cases-title {
    color: #68d1f4;
}

.global-tests-title {
    color: #f4bc68;
}

.global-deaths-title {
    color: #f6584c;
}

.global-vacc-title {
    color: #4cf6af;
}

.stats {
    margin: 15px 0 0 0;
    font-size: 2em;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    pointer-events: none;
}

.stats-perc {
    font-size: 1em !important;
}

.stats-titles {
    margin: 0;
    font-size: 1em;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    pointer-events: none;
}

.stats-flex {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 3px auto;
    cursor: pointer;
    color: #faebd7;
    padding: 0 12px;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

.stats-flex:hover {
    background-color: #79d7f4;
    color: #000 !important;
}

.ranks-title {
    margin: 0 0 20px 0;
    font-size: 1em;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

.inline-count {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30px;
    flex: 0 0 30px;
    margin: 0;
    font-size: 1em;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

.inline-flag {
    width: 32px;
    height: 28px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 32px;
    flex: 0 0 32px;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

.inline-p {
    font-size: 1em;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    margin: 0 10px;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

.inline-stat {
    font-size: 1em;
    margin: 0;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

/* end world stats */
/* end sideBar */
/* begin map */
#mapDiv,
#usMap {
    width: 100%;
    height: calc(100 * var(--vh));
    position: fixed;
    margin: 0 auto;
    z-index: 1;
    opacity: 0;
}

svg {
    overflow: hidden;
}

.map-div path {
    fill: #fff;
    fill-rule: evenodd;
    stroke-width: calc(0.1px/var(--zoom));
    -o-transition: fill 0.2s ease-in;
    -moz-transition: fill 0.2s ease-in;
    -webkit-transition: fill 0.2s ease-in;
    transition: fill 0.2s ease-in;
    cursor: pointer;
}

.map-div path:hover {
    stroke-width: calc(2px/var(--zoom));
}

#worldMap,
#usMap {
    width: 100%;
    height: 100%;
    stroke-linejoin: round;
    stroke: #fff;
    fill: none;
    stroke-width: 0.2px;
    position: relative;
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
}

.light #worldMap,
.light #usMap {
    stroke: #000;
}

/* end map */
/* begin popup */
#popup {
    position: absolute;
    background-color: #000;
    padding: 10px;
    border-radius: 8px;
    text-align: center;
    z-index: 10;
    -ms-touch-action: none;
    touch-action: none;
    opacity: 0.9;
    pointer-events: none;
}

.popup-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.popup-big,
.popup-small,
.country {
    display: block;
    margin: 0;
}

.popup-big {
    font-size: 1.5em;
    font-weight: 400;
    margin-left: 15px;
}

.popup-vaccines {
    max-width: 200px;
}

.country {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.flag {
    display: block;
    margin: 0 auto;
    width: 40px;
    height: 40px;
}

/* end popup */
/* begin about page */
#about {
    width: 100%;
    height: calc(100 * var(--vh));
    padding: 50px 5px 0px;
    overflow-y: scroll;
    position: absolute;
    z-index: 2;
    -webkit-touch-callout: text;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

#aboutWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 40px auto 0;
    max-width: 1100px;
    padding-bottom: 50px;
}

.about-flex {
    width: calc(50% - 10px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    background-color: #000;
    border-radius: 8px;
    padding: 20px;
    margin: 5px;
    opacity: 0.9;
    text-align: center;
}

.about-flex-100 {
    width: calc(100% - 10px);
}

.about-flex-col,
.about-flex-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 30px;
}

.about-flex-col {
    width: 100%;
}

.light .about-flex {
    background-color: #fff;
}

.about-dark-blue {
    background-color: #002029 !important;
}

.about-flex a:link,
.about-flex a:visited {
    color: #faebd7;
    cursor: pointer;
}

.light .about-flex a:link,
.light .about-flex a:visited {
    color: #000;
    cursor: pointer;
}

#about h1 {
    font-size: 3em;
    font-weight: 100;
    color: #54cbf2;
    margin: 10px 0;
}

.light #about h1 {
    color: #f3564a;
}

.text {
    font-size: 2em;
    color: #faebd7;
    margin: 0 0 20px 0;
}

.small-text {
    font-size: 1.3em;
    margin: 0;
    color: #cacaca;
}

.smallest-text {
    margin: 0;
    font-size: 0.8em;
    color: #cacaca;
}

.light .text {
    color: #000;
}

.light .small-text {
    color: #545454;
}

.light .text-over-blue {
    color: #fff !important;
}

/* end about page */
@media(max-width:1024px) {
    #header h1 {
        font-size: 1em;
    }

    .about-flex {
        width: calc(100% - 10px);
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    #countryPopup {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: 100%;
    }

    .country-wrapper {
        position: relative;
        top: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 10px;
        min-height: unset;
        height: auto;
        width: calc(100% - 5px);
    }

    .stats-column {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin: 10px;
    }

    .stats-column-flex {
        width: calc(50% - 5px);
    }

    .flex-stats-container {
        margin-top: 0;
        margin-left: 0;
    }

    #chart {
        width: calc(100% - 5px);
    }

    .chart-change {
        height: calc(calc(100 * var(--vh)) - 60px) !important;
    }
}

@media(max-width:768px) {
    .btn-container {
        margin: 0;
        padding-bottom: 20px;
    }

    #logo {
        margin-right: 10px;
    }

    #header h1 {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        font-size: 1em;
    }

    #searchWrapper {
        -webkit-box-flex: 0;
        -ms-flex: 0;
        flex: 0;
        margin: 0 30px 0 0;
        background-color: transparent;
    }

    #searchInput {
        visibility: hidden;
    }

    #closeSearch {
        display: none;
    }

    #globalInstructions {
        top: 91px;
    }

    .country-wrapper {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .country-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-flex: 100%;
        -ms-flex: 100%;
        flex: 100%;
    }

    .stats-column,
    .flex-stats-container {
        text-align: center;
    }

    .prop-title-main {
        width: auto !important;
    }

    .font-vw {
        font-size: 5vw;
        ;
    }

    #nav {
        display: none;
    }

    #hamburger {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 35px;
        height: 35px;
        cursor: pointer;
        position: absolute;
        top: 7px;
        right: 12px;
        pointer-events: all;
    }

    #line1,
    #line2 {
        margin: 3px 0;
        width: 25px;
        height: 2px;
        background-color: #faebd7;
        border-radius: 50px;
        -o-transition: 0.3s ease-in-out;
        -moz-transition: 0.3s ease-in-out;
        -webkit-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
    }

    .change #line1 {
        -o-transform: rotate(-45deg) translate(-1px, 5px);
        -moz-transform: rotate(-45deg) translate(-1px, 5px);
        -ms-transform: rotate(-45deg) translate(-1px, 5px);
        -webkit-transform: rotate(-45deg) translate(-1px, 5px);
        transform: rotate(-45deg) translate(-1px, 5px);
    }

    .change #line2 {
        -o-transform: rotate(45deg) translate(0px, -5px);
        -moz-transform: rotate(45deg) translate(0px, -5px);
        -ms-transform: rotate(45deg) translate(0px, -5px);
        -webkit-transform: rotate(45deg) translate(0px, -5px);
        transform: rotate(45deg) translate(0px, -5px);
    }

    .light #line1,
    .light #line2 {
        background-color: #000;
    }

    #mobileNav {
        width: 100%;
        position: absolute;
        top: 50px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        background-color: #000;
        -o-transition: transform 0.3s ease-out;
        -moz-transition: transform 0.3s ease-out;
        -webkit-transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        z-index: 11;
        -ms-touch-action: none;
        touch-action: none;
    }

    .light #mobileNav {
        background-color: #fff;
        box-shadow: 0px 0px 2px 0px black;
    }

    #mobileNav .menu-btns {
        width: 100%;
        text-align: center;
        margin: 0;
        padding: 15px 0;
    }

    #toggleSideBar,
    #centerBtn,
    #keyBtn,
    #toggleDark {
        background-color: unset !important;
        top: 53.5px !important;
        box-shadow: 0px 0px 2px 0px black;
        z-index: 9;
    }

    #keys {
        top: unset !important;
        right: unset !important;
        bottom: 0px;
        width: 100%;
        padding: 5px 10px 10px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    #keys h2,
    #keyTitle {
        display: none;
    }

    #closeKeys {
        position: fixed;
        bottom: 55px;
        right: 5px;
        background-color: #1f1f1f !important;
    }

    .light #closeKeys {
        background-color: #fff !important;
    }

    .keys {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 0px;
    }

    .colors-wrapper {
        width: 100%;
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        z-index: 1;
    }

    .flex-column {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    }

    .color {
        height: 15px;
        -webkit-box-flex: 1;
        -ms-flex: auto;
        flex: auto;
        margin: 0 0.5px;
    }

    .color:last-child {
        border-bottom-right-radius: 0px !important;
        border-bottom-left-radius: 3px !important;
        border-top-left-radius: 3px !important;
    }

    .key-data {
        width: 100%;
        margin-left: 0px;
        -ms-flex-item-align: start;
        align-self: flex-start;
        align-self: flex-start;
    }

    .data-range:last-child {
        white-space: unset;
        padding: 5px;
    }

    .data-range {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-box-flex: 1;
        -ms-flex: auto;
        flex: auto;
        height: 40px;
        font-size: 12px;
        margin: 0 0.5px;
        padding: 0 5px;
        -o-transform: translateX(var(--trans));
        -moz-transform: translateX(var(--trans));
        -ms-transform: translateX(var(--trans));
        -webkit-transform: translateX(var(--trans));
        transform: translateX(var(--trans));
    }

    .data-line,
    .data-line-small {
        display: block;
        width: 1px;
        background-color: #505050;
    }

    .data-line {
        height: 20px;
    }

    .data-line-small {
        height: 5px;
    }

    .light .data-line,
    .light .data-line-small {
        background-color: #e6e6e6;
    }

    #sideBar {
        width: 100%;
        top: 0px;
        opacity: 1;
        z-index: 15;
    }

    #toggleSideBar {
        left: 5px;
    }

    #keyBtn {
        right: 5px;
    }

    #currentDataWrapper {
        width: 100%;
        height: 41px;
        top: 50px;
        right: 0;
        z-index: 8;
    }

    #globalHelpTip {
        position: absolute;
        top: 70px;
        left: unset;
        right: 10px;
        margin-top: 0px;
    }

    #legendHelpTip {
        top: -14px;
        left: 5px;
    }

    #legendHelpTip p {
        right: unset;
        top: -78px;
    }

    #legendHelpTip p:before {
        /* The pointer of the tooltip */
        border-top-color: #faebd7;
        border-bottom-color: transparent;
        left: 10px !important;
        bottom: -12px;
        top: unset;
    }

    .light #legendHelpTip p::before {
        border-top-color: #282828;
    }

    #currentData {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 100%;
        text-align: center;
        border-radius: 0px;
        font-size: 0.8em;
        padding: 0 100px;
        opacity: 0.9;
    }

    #toggleDark {
        left: 47px;
    }

    #centerBtn {
        right: 47px;
    }

    #sideBar {
        padding-bottom: 0;
    }

    #aboutWrapper {
        margin-top: 10px;
    }

    .chart-header {
        padding-bottom: 20px;
    }

    #globalHelpTip p,
    #legendHelpTip p {
        width: 300px;
    }

    .help-tip p {
        width: 200px;
        right: -4px;
        left: unset;
        pointer-events: none;
    }

    .help-tip p::before {
        right: 10px;
        left: unset;
    }

    .help-tip p:after {
        height: 0;
    }

    .chart-popup-wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: 5px 0px;
    }

    .chart-popup-flex {
        width: 100%;
        margin: 0 !important;
    }

    #vaccineList {
        top: 95px;
        right: 2px;
    }
}

@media(max-width:520px) {
    #countryPopup {
        padding: 0;
    }

    .stats-column-flex {
        width: calc(100% - 5px);
    }

    .font-vw {
        font-size: 7.5vw;
    }

    #header h1 {
        font-size: 14px;
    }

    .country-big {
        text-align: center;
    }
}

@media(max-width:400px) {
    .chart-anim-btns p {
        display: none;
    }
}

@media(hover:hover) {

    .menu-btns:hover,
    .menu-btns:focus,
    .menu-btns:active {
        color: #79d7f4 !important;
    }

    .light .menu-btns:hover,
    .light .menu-btns:focus,
    .light .menu-btns:active {
        color: #9c9c9c !important;
    }

    #closeSearch:hover,
    #closePopup:hover {
        opacity: 0.7;
    }

    .us-btn:hover,
    .us-btn:focus {
        text-decoration: underline;
        box-shadow: 0px 1px 10px 0px #9a2532;
    }

    .chart-btns:hover,
    .chart-btns:focus {
        background-color: #96dcf4 !important;
        color: #000 !important;
    }

    .chart-anim-btns:hover svg path {
        fill: #000;
    }

    .chart-anim-btns:hover {
        background-color: #54cbf2;
        border-color: #54cbf2;
    }

    .light .chart-anim-btns:hover {
        background-color: #038ebc;
        border-color: #038ebc;
    }

    .chart-anim-btns:hover p {
        color: #000;
    }

    #toggleSideBar:hover,
    #centerBtn:hover,
    #keyBtn:hover,
    #toggleDark:hover {
        background-color: #cacaca;
    }

    #closeKeys:hover {
        color: unset !important;
        background-color: #3b3a38;
    }

    .light #closeKeys:hover {
        color: unset !important;
        background-color: #cacaca;
    }

    .closeBtn:hover {
        color: #626262;
    }

    .light .closeBtn:hover {
        color: #cacaca;
    }

    .dropDown-arrow:hover,
    .dropDown-arrow:active,
    .dropDown-arrow:focus {
        opacity: 1;
    }

    .cases-btns:hover {
        background-color: #96dcf4;
        color: #000;
    }

    .tests-btns:hover {
        background-color: #f9d7a4;
        color: #000;
    }

    .deaths-btns:hover {
        background-color: #ff9890;
        color: #000;
    }

    .vacc-btns:hover,
    .vacc-list-btns:hover {
        background-color: #bafbdf;
        color: #000;
    }

    .about-flex a:hover,
    .about-flex a:focus {
        text-decoration: none;
        color: #545454;
    }

    .light .about-flex a:hover,
    .light .about-flex a:focus {
        text-decoration: none;
        color: #545454;
    }
}